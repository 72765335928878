<template>
  <div id="gjs"></div>
</template>
  
<script>
import grapesjs from 'grapesjs'
import 'grapesjs/dist/css/grapes.min.css'
import 'grapesjs/dist/grapes.min.js'
import grapesjsPresetWebpage from 'grapesjs-preset-webpage'
import grapesjsBlocksBasic from 'grapesjs-blocks-basic'
import grapesjsPresetNewsletter from 'grapesjs-preset-newsletter'
import { reqGetPage, reqUpdatePageBuilder } from '@/api/admin/page'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
  name: 'WebBuilder',
  data() {
    return {
      formData: {},
      token: localStorage.getItem('accessToken')
    }
  },
  mounted() {
    this.initGrapeJs()
  },
  methods: {
    getPage() {
      reqGetPage(this.$route.params.id).then(response => {
        return response.data.data
      })
    },
    async submitForm(data) {
      await reqUpdatePageBuilder(this.$route.params.id, {
        pages: JSON.stringify(data.pages),
        assets: JSON.stringify(data.assets),
        styles: JSON.stringify(data.styles),
        html: data.html,
        css: data.css
      })
        .then(response => {
          this.isLoading = false
          this.formErrors = {}

          this.showToast(
            'success',
            'CheckIcon',
            'Berhasil',
            response.data.message || 'User Berhasil Disimpan',
          )
        })
        .catch(error => {
          this.isLoading = false
          this.formErrors = error.response.data.errors

          this.showToast(
            'danger',
            'AlertTriangleIcon',
            'Gagal',
            error.response.data.message || 'User Gagal Disimpan',
          )
        })
    },
    initGrapeJs() {
      const _self = this
      const projectEndpoint = `http://api-tekov.test/api/admin/pages`;
      const editor = grapesjs.init({
        container: '#gjs',
        height: '100%',
        width: '100%',
        storageManager: {
          type: 'remote',
          stepsBeforeSave: 1,
          autosave: true,         // Store data automatically
          autoload: true,
          options: {
            remote: {
              contentTypeJson: true,
              storeComponents: true,
              storeStyles: true,
              storeHtml: true,
              storeCss: true,
              onStore: (data, editor) => {
                const pagesHtml = editor.Pages.getAll().map(page => {
                  const component = page.getMainComponent();
                  return {
                    html: editor.getHtml({ component }),
                    css: editor.getCss({ component })
                  }
                });
                return { ...data, ...pagesHtml[0] };
              },
            }
          }
        },
        plugins: [
          grapesjsBlocksBasic,
          grapesjsPresetNewsletter,
          editor => grapesjsPresetWebpage(editor, {
            blocksBasicOpts: {
              blocks: ['column1', 'column2', 'column3', 'column3-7', 'text', 'link', 'image', 'video'],
              flexGrid: 1,
            },
          }),
        ],
        deviceManager: {
          devices:
            [
              {
                id: 'desktop',
                name: 'Desktop',
                width: '',
              },
              {
                id: 'tablet',
                name: 'Tablet',
                width: '768px',
                widthMedia: '992px',
              },
              {
                id: 'mobilePortrait',
                name: 'Mobile portrait',
                width: '320px',
                widthMedia: '575px',
              },
            ]
        },
      })
      /* editor.Panels.addButton('options', [
        {
          id: 'save',
          className: 'fa fa-floppy-o icon-blank',
          command: function (editor1, sender) {
            _self.submitForm(editor1)
          },
          attributes: { title: 'Save Template' }
        },]);
 */
      editor.Storage.add('remote', {
        async load() {
          return await reqGetPage(_self.$route.params.id).then(response => {
            if (response.data.data.pages && response.data.data.styles) {
              return response.data.data
            }
          })
        },

        async store(data) {
          return await _self.submitForm(data)
        },
      });
    },
    showToast(variant, icon, title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text: message,
          variant,
        },
      })
    },
  }
}
</script>
<style></style>